import {useCallback, useContext} from 'react';
import {ReCaptchaContext} from './GoogleReCaptchaProvider'; // Путь к вашему провайдеру

export function useGoogleReCaptcha() {
    const { recaptcha, reCaptchaKey } = useContext(ReCaptchaContext);

    const executeRecaptcha = useCallback(
        async (action) => {
            if (!recaptcha) {
                throw new Error('reCAPTCHA didnt load.');
            }
            try {
                return await recaptcha.enterprise.execute(reCaptchaKey, {action});
            } catch (e) {
                console.error('Error executing reCAPTCHA:', e);
                throw e;
            }
        },
        [recaptcha, reCaptchaKey]
    );

    return { executeRecaptcha };
}