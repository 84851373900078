import React, {createContext, useEffect, useState} from 'react';

export const ReCaptchaContext = createContext(undefined);

export function GoogleReCaptchaProvider({reCaptchaKey, language, children}) {
    const [recaptcha, setRecaptcha] = useState(null);

    useEffect(() => {

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaKey}&hl=${language}`;
        script.async = true;
        script.defer = true;
        script.id = 'recaptcha-script';
        script.onerror = () => {
            console.error('Error load reCAPTCHA script.');
        };

        document.head.appendChild(script);

        script.onload = () => {
            setRecaptcha(window.grecaptcha);
        };

        return () => {

            const recaptchaScript = document.getElementById('recaptcha-script');
            if (recaptchaScript) {
                document.head.removeChild(recaptchaScript);
            }

            const recaptchaBadge = document.querySelector('.grecaptcha-badge');
            if (recaptchaBadge && recaptchaBadge.parentElement) {
                recaptchaBadge.parentElement.remove();
            }

            if (window.grecaptcha) {
                delete window.grecaptcha;
            }
        };
    }, [reCaptchaKey, language]);

    const value = {recaptcha, reCaptchaKey};

    return <ReCaptchaContext.Provider value={value}>{children}</ReCaptchaContext.Provider>;
}
